<template>
  <div>
    <b-row>
      <b-col>
        <div class="ql-wrapper">
          <div class="ql-container ql-snow">
            <div
              ref="viewer"
              class="viewer ql-editor"
              @mousedown.stop.prevent="editorModal = true"
            ></div>
          </div>
        </div>
        <div>
          <ol v-if="answerText.content.links" class="mt-2 pl-4">
            <li
              v-for="link in answerText.content.links"
              :key="link.startPosition"
              class="text-muted"
              style="font-size: 0.9em"
            >
              <small class="text-muted"
                >[{{
                  answerText.content.message.substring(
                    link.startPosition,
                    link.endPosition + 1
                  )
                }}] ({{ link.url || getIntentName(link.intentId) }})</small
              >
            </li>
          </ol>
        </div>
      </b-col>
    </b-row>

    <b-modal
      v-model="editorModal"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      @shown="
        () => {
          let length = this.quillEditor.getLength();
          this.quillEditor.focus();
          this.quillEditor.setSelection(length, 0);
        }
      "
    >
      <b-row class="mb-3">
        <b-col>
          <b-button v-b-toggle.collapse-1 variant="outline-primary" size="sm"
            >Show HTML
          </b-button>
          <b-collapse id="collapse-1" class="mt-2">
            <div
              contenteditable="false"
              class="html-display"
              title="text in html"
            >
              {{ answer }}
            </div>
          </b-collapse>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <vue-editor
            id="editor"
            class="vue-editor"
            ref="editor"
            v-model="answer"
            :editorOptions="editorOptions"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            class="float-right"
            size="sm"
            variant="primary"
            @click="updateAnswerText"
            >Apply
          </b-button>
          <b-button
            class="float-right mr-2"
            size="sm"
            variant="secondary"
            @click="answerTextUpdated"
            >Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      v-model="linkModal"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <b-form ref="linkForm" @submit.stop.prevent="addLink">
        <b-row class="mb-3">
          <b-col>
            <b-card no-body>
              <b-tabs
                card
                small
                @input="
                  (i) => {
                    if (i === 1) {
                      this.linkForm.intentId = null;
                    }
                  }
                "
              >
                <b-tab title="Intent Link" lazy>
                  <b-row class="mb-3">
                    <b-col cols="12" lg="3" xl="2">
                      <label for="link-form-text">Text</label>
                    </b-col>
                    <b-col>
                      <b-input
                        v-model="linkForm.text"
                        id="link-form-text"
                        size="sm"
                        required
                        disabled
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="12" lg="3" xl="2">Intent</b-col>
                    <b-col>
                      <treeselect
                        required
                        v-model="linkForm.intentId"
                        :options="intents"
                        :normalizer="
                          (node) => {
                            return {
                              id: node.intentId,
                              label: node.intentName,
                            };
                          }
                        "
                        style="width: calc(100% - 18px)"
                      />
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Html Link" lazy @>
                  <b-row class="mb-3">
                    <b-col cols="12" lg="3" xl="2">
                      <label for="link-form-text">Text</label>
                    </b-col>
                    <b-col>
                      <b-input
                        v-model="linkForm.text"
                        id="link-form-text"
                        size="sm"
                        required
                        disabled
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="12" lg="3" xl="2">Link</b-col>
                    <b-col>
                      <b-input v-model="linkForm.url" type="url" size="sm" />
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              class="float-right ml-2"
              size="sm"
              variant="primary"
              @click="addLink"
            >
              Add link
            </b-button>
            <b-button
              class="float-right"
              size="sm"
              variant="secondary"
              @click="linkModal = false"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import sanitizeHtml from 'sanitize-html';
import { VueEditor, Quill } from 'vue2-editor';
import 'quill/dist/quill.snow.css';

const Link = Quill.import('formats/bold');

// console.log(Quill.imports);

class IntentLink extends Link {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('href', 'goToIntent:');
    node.setAttribute('data-id', value);
    node.removeAttribute('target');
    return node;
  }
}

IntentLink.blotName = 'ilink';
IntentLink.tagName = 'A';
Quill.register({ 'formats/ilink': IntentLink });

class PlainLink extends Link {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('href', value);
    node.removeAttribute('target');
    return node;
  }
}

PlainLink.blotName = 'plink';
PlainLink.tagName = 'A';
Quill.register({ 'formats/plink': PlainLink });

export default {
  name: 'AnswerTypeText',
  components: { VueEditor },
  props: ['answerText', 'intents'],
  data() {
    return {
      answer: null,
      editorModal: false,
      linkModal: false,
      linkForm: _defaultLinkForm(),
      editorOptions: {
        theme: 'snow',
        formats: [
          'plink',
          'ilink',
          'link',
          'clean',
          'bold',
          'italic',
          'underline',
          'strike',
          'color',
          'background',
        ],
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{ color: [] }],
              ['link', 'plink'],
              ['clean'],
            ],
            handlers: {
              link: (v) => {
                const selection = this.quillEditor.getSelection();
                const format = this.quillEditor.getFormat();
                if (format.ilink || format.plink) {
                  this.quillEditor.removeFormat(
                    selection.index,
                    selection.length
                  );
                } else {
                  if (v) {
                    if (selection.length > 0) {
                      this.linkForm = {
                        ..._defaultLinkForm(),
                        ...{
                          text: this.quillEditor.getText(
                            selection.index,
                            selection.length
                          ),
                        },
                      };
                      this.linkModal = true;
                    } else {
                      this.$bvModal.msgBoxOk(
                        'Please select text to change to link',
                        { centered: true, buttonSize: 'sm' }
                      );
                    }
                  }
                }
              },
            },
          },
        },
      },
    };
  },
  mounted() {
    this.answerTextUpdated();
  },
  watch: {
    answerText() {
      this.answerTextUpdated();
    },
  },
  computed: {
    quillEditor() {
      return this.$refs['editor'].quill;
    },
  },
  methods: {
    getIntentName(intentId) {
      intentId = parseInt(intentId);
      const intent = _.find(this.intents, { intentId: intentId });
      if (intent) {
        return 'Intent: ' + intent.intentName + ', ' + intentId;
      }
      return 'Intent Id: ' + intentId;
    },
    answerTextUpdated() {
      const answerText = this.answerText;
      if (
        answerText &&
        Object.prototype.hasOwnProperty.call(answerText, 'content')
      ) {
        let content = answerText.content;
        let message = content.message;
        let newMessage = '';

        if (content.links) {
          let links = content.links;
          if (links.length > 0) {
            let currentPosition = 0;
            _.forEach(_.sortBy(links, ['startPosition']), function (link) {
              newMessage =
                newMessage +
                message.substring(currentPosition, link.startPosition);
              //forcing it to +1 because of c1
              let endPosition = link.endPosition + 1;
              let text = message.substring(link.startPosition, endPosition);
              let type = link.type;
              let a = document.createElement('a');
              let linkStr;

              if (type === 'goToIntent') {
                a.setAttribute('href', 'goToIntent:');
                a.setAttribute('data-id', link.intentId);
                a.innerText = text;
                linkStr = a.outerHTML;
              } else if (type === 'hyperlink') {
                a = document.createElement('a', {
                  href: link.url,
                });
                a.setAttribute('href', link.url);
                a.innerText = text;
                linkStr = a.outerHTML;
              }
              newMessage = newMessage + linkStr;
              currentPosition = endPosition;
            });

            if (currentPosition < message.length) {
              newMessage = newMessage + message.substring(currentPosition);
            }
          } else {
            newMessage = message;
          }
        }
        this.answer = newMessage;
        this.$refs['viewer'].innerHTML = this.answer;
        this.editorModal = false;
      }
    },
    updateAnswerText() {
      let links = [];
      let inputAnswer = _.cloneDeep(this.answer);
      inputAnswer = inputAnswer.replaceAll('&nbsp;', '');
      inputAnswer = sanitizeHtml(inputAnswer, {
        allowedTags: ['a', 'br', 'p', 'strong', 'em', 'u', 'span'],
        allowedAttributes: {
          a: ['href', 'data-id', 'style'],
          span: ['style'],
          p: ['style'],
          em: ['style'],
          u: ['style'],
          strong: ['style'],
        },
        allowedSchemesAppliedToAttributes: ['src', 'cite'],
      });

      const divElement = document.createElement('div');
      divElement.innerHTML = inputAnswer;

      const linkElements = divElement.querySelectorAll('a');

      _.each(linkElements, (linkElement) => {
        let text = linkElement.innerText;
        let innerHtml = divElement.innerHTML;
        innerHtml = innerHtml.replaceAll('&amp;', '&');
        let startPosition = innerHtml.indexOf('<a');
        //forcing it to -1 because of c1
        let endPosition = startPosition + text.length - 1;
        let href = linkElement.getAttribute('href');
        let intentId = linkElement.getAttribute('data-id');
        let link = {};

        if (href === 'goToIntent:') {
          link = {
            type: 'goToIntent',
            startPosition: startPosition,
            endPosition: endPosition,
            intentId: intentId,
            displayText: text,
          };
        } else {
          link = {
            type: 'hyperlink',
            startPosition: startPosition,
            endPosition: endPosition,
            ifPushPage: true,
            url: href,
            openIn: 'currentWindow', // currentWindow, sideWindow or newWindow.
          };
        }
        links.push(link);
        linkElement.insertAdjacentText('afterend', text);
        linkElement.remove();
      });

      let cleanInputAnswer = divElement.innerHTML;
      cleanInputAnswer = cleanInputAnswer.replaceAll('&amp;', '&');

      this.$emit('update:answerText', {
        type: 'text',
        content: {
          message: cleanInputAnswer,
          links: links,
        },
      });

      divElement.remove();

      this.editorModal = false;
    },
    addLink() {
      const linkForm = { ...this.linkForm };
      if (linkForm.intentId) {
        this.quillEditor.format('ilink', linkForm.intentId);
      } else if (linkForm.url) {
        this.quillEditor.format('plink', linkForm.url);
      }
      // const selection = this.quillEditor.getSelection();
      // this.quillEditor.setSelection(selection.index + selection.length, 0);
      this.quillEditor.focus();
      this.quillEditor.setSelection(this.quillEditor.getLength(), 0);
      this.linkModal = false;
    },
  },
};

function _defaultLinkForm() {
  return {
    text: '',
    url: 'https://',
    intentId: null,
  };
}
</script>
<style>
/*.vue-editor .ql-toolbar {*/
/*  text-align: right;*/
/*  padding: 4px 8px 8px 0 !important;*/
/*}*/

/*.vue-editor .ql-formats {*/
/*  margin: 0 !important;*/
/*}*/
</style>

<style scoped>
.viewer {
  cursor: all-scroll;
  min-height: 180px !important;
}

.html-display {
  border: 1px solid #bebebe;
  padding: 5px;
  font-size: 0.8em;
}

.text-editor {
  border: #ced4da solid 1px;
  border-radius: 0 0 5px 5px;
  padding: 0.5em;
  min-height: 120px;
}

.text-editor:focus {
  outline: none;
}

.trumbowyg-box {
  margin: 0 !important;
}
</style>
